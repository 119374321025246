import { Link } from '@BlackbirdHQ/ui-base';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  media: {
    height: 150,
    marginTop: theme.spacing(2),
  },
  card: {
    width: '100%',
    maxWidth: '500px',
  },
  fillHeight: {
    height: '100%',
  },
}));

const NotFound: NextPage = () => {
  const [t] = useTranslation();
  const { classes } = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (!router.asPath.endsWith('/')) {
      router.replace([router.asPath, '/'].join(''));
    }
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.fillHeight}>
      <Grid item>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5">
              {t(['shared:nothingToSeeHere'], { defaultValue: 'Nothing to see here...' })}
            </Typography>
            <Typography>
              {t(['shared:thePageYouRequestedDoesNotExist'], {
                defaultValue: 'The page you requested does not exist.',
              })}
            </Typography>
          </CardContent>
          <CardActions>
            <Link href={{ pathname: '/' }}>
              <Button color="primary">
                {t(['shared:returnToFrontPage'], { defaultValue: 'Return to front page' })}
              </Button>
            </Link>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      namespaces: ['shared'],
    },
  };
};

export default NotFound;
